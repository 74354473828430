import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  videoContainer: {
    width: "100vw",
    height: "56.25vw",
    overflow: "hidden",
    position: "relative",
  },
  videoResponsive: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    "& iframe": {
      width: "100%",
      height: "100%",
    },
  },
});

function YoutubeEmbed({ classes }) {
  return (
    <div className={classes.videoContainer}>
      <div className={classes.videoResponsive}>
        <iframe
          src="https://www.youtube-nocookie.com/embed/FSBLZ7prOsY?autoplay=1&mute=1&controls=0&loop=1&playlist=FSBLZ7prOsY&enablejsapi=1&modestbranding=1&showinfo=0&rel=0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Pat's Reel"
        />
      </div>
      <div className={classes.overlay}></div>
    </div>
  );
}

export default withStyles(styles)(YoutubeEmbed);
